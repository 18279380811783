import type { Config } from '@bugsnag/js'
import Bugsnag from '@bugsnag/js'
import type { EventError } from '@sonicgarden/bugsnag-plugin-custom-error'
import { BugsnagPluginCustomError } from '@sonicgarden/bugsnag-plugin-custom-error'

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const loggerOptions = (): Pick<Config, 'logger'> => {
  // eslint-disable-next-line unicorn/no-null
  return import.meta.env.RAILS_ENV === 'test' ? { logger: null } : {}
}

const userOptions = (): Pick<Config, 'user'> => {
  if (!window.GA.rails.user) return {}

  const { id, email } = window.GA.rails.user
  return {
    user: { id: id.toString(), email },
  }
}

const customErrorPlugin = new BugsnagPluginCustomError({
  NetworkError: ['Network Error', 'timeout of 0ms exceeded', 'Request aborted', 'Failed to fetch'],
  MemoryError: [
    'メモリが不足しています。',
    'この操作を完了するための十分な記憶域がありません。',
    'メモリ リソースが不足しているため、この操作を完了できません。',
  ],
  BadGatewayError: ['Request failed with status code 502'],
  ResizeObserverError: ['ResizeObserver loop limit exceeded'],
  ServiceWorkerError: ['Failed to register a ServiceWorker for scope'],
  // eslint-disable-next-line github/unescaped-html-literal
  SafariWebExtensionError: ['<page-ready>', '<get-frame-manager-configuration>'],
  GoogleApiError: [
    (error: EventError) => error.stacktrace.some((frame) => frame.file.includes('translate.googleapis.com')),
  ],
  ChatPlusError: [(error: EventError) => error.stacktrace.some((frame) => frame.file.includes('chatplus.jp'))],
})

window.bugsnagClient = Bugsnag.start({
  apiKey: 'a3d69a13d2c8c49a0d839826b90c8584',
  releaseStage: import.meta.env.RAILS_ENV,
  enabledReleaseStages: ['staging', 'production'],
  plugins: [customErrorPlugin],
  ...loggerOptions(),
  ...userOptions(),
})
